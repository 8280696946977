.cart {
  min-height: 70vh;
}
.cart-container {
  display: grid;
  max-width: 1200px;
  margin: auto;
  padding: 125px 20px;
}
.cart-content {
  /* border: 1px solid #000; */
  display: grid;
  gap: 20px;
}
.cart-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 20px;
}
.cart-image {
  width: 80%;
}
.cart-quantity {
  display: flex;
  align-items: center;
}
.cart-quantity p {
  margin: 0 30px;
}
.total-price {
  text-align: end;
}

.button-quantity {
  background-color: #f59c00;
  border-radius: 5px;
  color: #000;
  font-size: 20px;
  padding: 2px 10px;
  text-align: center;
  font-weight: 600;
  border: none;
  transition-duration: 0.3s;
}
.disabled {
  opacity: 0.5;
}
.button-quantity:hover {
  background-color: #004f9f;
  color: #fff;
  transition-duration: 0.3s;
}

.checkout-button {
  margin-top: 20px;
  justify-self: end;
  border: 1px solid #f59c00;
  text-decoration: none;
  color: #000;
  background-color: #f59c00;
  padding: 10px 30px;
  border-radius: 5px;
  transition-duration: 0.3s;
}

.checkout-button:hover {
  border: 1px solid #004f9f;
  transition-duration: 0.3s;
  color: #fff;
  background-color: #004f9f;
}

@media (max-width: 750px) {
  .cart-item {
    grid-template-columns: 1fr;
    gap: 20px;
    justify-self: center;
    text-align: center;
  }
  .cart-item img {
    margin: auto;
  }
  .cart-quantity {
    display: flex;
    justify-content: center;
  }
  .cart-container {
    padding: 50px 20px;
  }
}
