.not-found-container {
  min-height: 70vh;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.not-found-content {
  display: flex;
  margin: auto;
  width: 100%;
  flex-direction: column;
}
.back-button {
  margin: auto;
}
.back-button button {
  margin: 20px 50px 0 0;
}

.not-found-image {
  margin: auto;
  width: 100%;
  height: 540px;
  background-size: contain !important;
  background-image: url('../../assets/404.png');
}

@media (max-width: 700px) {
  .not-found-image {
    height: 300px;
  }
  .back-button button {
    margin: 20px 30px 0 0;
  }
}

@media (max-width: 500px) {
  .back-button button {
    margin: 20px 30px 0 0;
  }
}
