.about-us-container {
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 20px;
  min-height: 70vh;
}
.background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.about-us-content {
  padding: 100px;
}
.about-us-content-title {
  padding-bottom: 55px;
  font-size: 34px;
  text-align: center;
}
.middle-text {
  padding: 30px 0 100px 0;
}

.boss-image {
  height: 584px;
  background-image: url('../../assets/boss.png');
}

@media (max-width: 993px) {
  .about-us-content {
    padding: 50px;
  }
}
@media (max-width: 700px) {
  .about-us-container {
    grid-template-columns: 1fr;
    padding: 50px 20px;
    min-height: 70vh;
  }
  .about-us-content {
    padding: 0 0 50px 0;
    grid-area: 1;
  }
  .about-us-content-title {
    text-align: center;
  }
}
