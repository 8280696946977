.header-container {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-link {
  padding: 10px 20px;
  color: #004f9f;
  font-size: 18px;
  text-decoration: none;
  transition-duration: 0.3s;
}
.header-link:hover {
  color: #f59c00;
  transition-duration: 0.3s;
}

.header-phone-button {
  background-color: transparent;
  border: none;
  color: #004f9f;
  padding: 15px 12px 4px 15px;
  border-bottom: 1px solid #004f9f;
  font-size: 18px;
  text-decoration: none;
  transition-duration: 0.3s;
}
.header-phone-button:hover {
  color: #f59c00;
  border-bottom: 1px solid #f59c00;
  transition-duration: 0.3s;
}
.header-cart-button {
  background-color: transparent;
  border: none;
  color: #004f9f;
  margin: 15px 12px 4px 15px;
  width: 30px;
  font-size: 18px;
  text-decoration: none;
  transition-duration: 0.3s;
  position: relative;
}
.header-cart-button svg {
  height: 25px;
  width: 25px;
}
.header-cart-button:hover {
  color: #f59c00;
  transition-duration: 0.3s;
}
.total-cart {
  background-color: #f59c00;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.cartBlock {
  position: relative !important;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}
.navbar-expand-lg .navbar-nav {
  padding-top: 20px;
}
.dropdown-item {
  text-align: center !important;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  text-align: center !important;
}

#collasible-nav-dropdown {
  margin-top: 15px;
  margin-right: 10px;
  padding: 0;
}
.dropdown-menu {
  min-width: 100px !important;
}

.icon {
  width: 30px;
}

@media (max-width: 991px) {
  .header-container {
    display: flex;
    flex-direction: column;
  }
  .header-cart-button {
    margin-right: 10px;
    width: initial;
  }
  .navbar-nav .dropdown-menu {
    position: absolute !important;
  }
  .mobile-language-cart-block {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    align-items: center;
  }
  .mobile-toggle-block {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
  }
  .navbar-expand-lg .navbar-nav {
    padding-top: 0px;
  }
  #collasible-nav-dropdown {
    margin-top: 0px;
    margin-right: 0px;
    padding: 0;
  }
  .header-cart-button {
    margin: 0 10px;
  }
  .navbar-collapse {
    text-align: center;
  }
  .nav-link {
    display: initial !important;
  }
  .total-cart {
    top: -14px;
    right: 6px;
  }
}
