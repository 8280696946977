.footer {
  background-color: #004f9f;
  padding: 50px 0;
}
.footer-content {
  max-width: 1200px;
  margin: auto;
}
.footer-container {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-logo {
  width: 166px;
  height: 82px;
}
.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-link {
  margin: 10px 20px;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  transition-duration: 0.3s;
  font-family: Raleway;
}
.footer-link:hover {
  color: #f59c00;
  transition-duration: 0.3s;
}

@media (max-width: 700px) {
  .footer-container {
    align-items: flex-start;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .footerLogo {
    width: 120px;
  }
}
@media (max-width: 400px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
