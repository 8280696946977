.checkout {
  min-height: 70vh;
}
.checkout-tabs-container {
  max-width: 1200px;
  margin: auto;
}
.checkout-data-container {
  padding: 30px;
}

.checkout-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding: 100px 20px;
  max-width: 1200px;
  margin: auto;
  min-height: 70vh;
  box-shadow: none !important;
}
.checkout-data-container {
  background-color: rgb(225, 225, 225);
}
.checkout-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 30px;
}
.checkout-item {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 100px;
  margin-bottom: 30px;
}
.checkout-item img {
  width: 40%;
}
.checkout-item:last-child {
  margin-bottom: 0px;
}
.checkout-item-description {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.price-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* input */
.input {
  margin-bottom: 20px;
}

.input-label {
  margin-right: 10px;
}

.choose-delivery {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.choose-delivery-title {
  padding-top: 40px;
  padding-bottom: 20px;
}
.checkout-text {
  padding: 20px 0;
}

.react-tel-input .form-control {
  width: 100%;
  height: 100%;
  padding: 16.5px 50px;
  margin-bottom: 20px !important;
}

@media (max-width: 900px) {
  .checkout-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 50px 20px;
  }
  .checkout-item-description {
    flex-direction: column;
  }
  .MuiStepper-horizontal {
    display: none !important;
  }
}
